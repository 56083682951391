<template>
    <div class="page">
      <ClientCalcCompliance :userId="$route.params.id" userType='CorpClient'/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientCalcCompliance from '@/components/clients/profile/ClientCalcCompliance';

export default {
  name: 'ClientCalcCompliancePage',
  components: {
    ClientCalcCompliance,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
