<template>
  <div>
    <div v-if="loading.codes" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <h1 class="font-lg mb-5">Расчет соответствия</h1>
    <div class="profile-board mb-5">

      <div v-if="!$store.getters['auth/isAllowed']('view_promocodecorpclient') && !$store.getters['auth/isAllowed']('view_promocodeuser')">
        У вас нет прав на просмотр этой информации
      </div>

      <v-tabs v-model="mainTab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab class="no-caps" v-if="$store.getters['auth/isAllowed']('view_promocodeuser')">Настройки</v-tab>
        <v-tab  class="no-caps" v-if="$store.getters['auth/isAllowed']('view_promocodecorpclient')">Кандидаты</v-tab>
      </v-tabs>
      <v-tabs-items v-model="mainTab">
        <v-tab-item v-if="$store.getters['auth/isAllowed']('view_promocodeuser')">
          <h3 class="font-md mt-5">Выбор профессиограмм</h3>

        </v-tab-item>

        <v-tab-item v-if="$store.getters['auth/isAllowed']('view_promocodecorpclient')">
          <div class="row">
            <div class="col-12 d-flex align-center justify-space-between">
              <h3 class="font-md mt-5">Кандидаты</h3>
              <v-btn class="ml-auto" color="primary" small outlined
                v-if="$store.getters['auth/isAllowed']('add_promocodecorpclient')">
                Выгрузка в таблицу
              </v-btn>
            </div>
          </div>

          <v-data-table :headers="headersCodes" :items="codes" class="mt-4"
                    :loading="loading.codes" loading-text="Загрузка... Пожалуйста подождите"
                    :mobile-breakpoint="300" fixed-header hide-default-footer :items-per-page="-1"
          >
            <template v-slot:item.title="{ item }"><span :class="{expired: item.status==='expired'}">{{item.title}}</span></template>
            <template v-slot:item.promocode="{ item }"><span :class="{expired: item.status==='expired'}">{{item.promocode}}</span></template>
            <template v-slot:item.package_size="{ item }"><span :class="{expired: item.status==='expired'}">{{item.package_size}}</span></template>
            <template v-slot:item.tests_for_distributors="{ item }"><span :class="{expired: item.status==='expired'}">{{item.tests_for_distributors}}</span></template>
            <template v-slot:item.tests_for_users="{ item }"><span :class="{expired: item.status==='expired'}">{{item.tests_for_users}}</span></template>
            <template v-slot:item.date_from="{ item }"><span :class="{expired: item.status==='expired'}">{{item.date_from}}</span></template>
            <template v-slot:item.date_to="{ item }"><span :class="{expired: item.status==='expired'}">{{item.date_to}}</span></template>
            <template v-slot:item.date_to="{ item }"><span :class="{expired: item.status==='expired'}">{{item.date_to}}</span></template>

            <template v-slot:item.url="{ item }">
              <v-btn text fab small :href="item.url ? item.url : `/promocode/${item.promocode}`" :disabled="!item.url" target="_blank" color="primary">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu bottom offset-y v-if="item.status!=='expired' && $store.getters['auth/isAllowed']('add_promocodeuser') ">
               <!-- !!!!! Если добавятся новые пункты меню, надо убрать ^ v-if -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text fab small v-bind="attrs" v-on="on" :loading="loading.activate"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="$store.getters['auth/isAllowed']('add_promocodeuser')" @click="activate(item)"><v-list-item-title>Активировать</v-list-item-title></v-list-item>
                    </v-list>
                  </v-menu>
            </template>
            <template v-slot:no-data>
              Список промокодов пуст
            </template>
          </v-data-table>

          <AddPromocodeSimpleDialog v-model="addPromocodeAccessibleDialog" :client="client" :existingCodes="codes"
            :partnerId="client ? client.supervisor : 0" @ok="reload()" />

        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddPromocodeSimpleDialog from '@/components/clients/profile/AddPromocodeSimpleDialog';

export default {
  name: 'PromocodesPage',
  components: {
    AddPromocodeSimpleDialog,
  },
  props: ['userId', 'userType'],
  inject: {
    isProfile: {
      default: false
    }
  },
  data() {
    return {
      mainTab: false,
      addPromocodeAccessibleDialog: false,
      addPromocodeActivatedDialog: false,
      headersCodes: [
        { text: 'Название', value: 'title', },
        { text: 'Ссылка', value: 'url', },
        { text: 'Код', value: 'promocode', },
        { text: 'Число тестов в пакете', value: 'package_size', sortable: false, },
        { text: 'число тестов для распространяющего', value: 'tests_for_distributors', sortable: false, },
        { text: 'Число тестов для покупающего', value: 'tests_for_users', sortable: false, },
        {
          text: 'Дата начала', align: 'center', value: 'date_from', sortable: false,
        },
        {
          text: 'Дата окончания', align: 'center', value: 'date_to', sortable: false,
        },
        {
          text: '', value: 'actions', sortable: false, align: 'center',
        },
      ],

    };
  },
  computed: {
    ...mapGetters({
      codes: 'profile/refs/codes',
      codesUsed: 'profile/refs/codesUsed',
      defaultRef: 'profile/refs/defaultRef',
      loading: 'profile/refs/loading',
      user: 'auth/user',
      client: 'users/clients/client',
    }),
    breadcrumbs() {
      if (!this.client) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
        { title: 'Расчет соответствия', url: '/compliance' },
      ] : [
        { title: 'Корпоративные клиенты', url: '/clients/' },
        { title: `${this.client.last_name} ${this.client.first_name}`, url: `/clients/${this.client.id}` },
        { title: 'Расчет соответствия', url: `/clients/${this.client.id}/compliance` },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadClient: 'users/clients/loadClient',
      loadCodes: 'profile/refs/loadCodes',
      loadCodesUsed: 'profile/refs/loadCodesUsed',
      activateClientPromocode: 'profile/refs/activateClientPromocode'
    }),
    async activate(item) {
      const response = await this.activateClientPromocode({ promocode: item.id, distributor: this.userId, client: this.client.id });
      if (response.error) {
        this.$toast.error(response.error, '', { position: 'topRight' });
      }
      await this.load();
    },
    async load() {
      await this.loadClient(this.userId);
      await this.loadCodes({ user_type: this.userType, id: this.userId });
      await this.loadCodesUsed({ user_type: this.userType, id: this.userId });
    },
    async reload() {
      await this.loadCodes({ user_type: this.userType, id: this.userId });
      await this.loadCodesUsed({ user_type: this.userType, id: this.userId });
    },
  },
  async created() {
    await this.load();
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.expired {
  color: #9BA1B5;
}

</style>
